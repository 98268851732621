import { environment } from '../../../../environments/environment';

export const errorCodes = environment.production
    ? {
        SAP_SIM_ORD_CONNECTION:
            'System Authorization, Timeout, and Service Unavailable: We have an issue with connecting to out backend system. Our support team has been notified. Please come back later and try again. We are sorry for the inconvenience.',
        SAP_SIM_ORD_SERVER:
            'Connection Issue (Server Error): Session time out. Please try with refreshing this page. If the service is not available due to unplanned maintenance, we apologize for the inconvenience and kindly ask you to come back in a few minutes.',
        SAP_SIM_ORD_DATAERR:
            'Data Issue: This service is currently unavailable. Our support team is already working on fixing the error. We apologize for the inconvenience and kindly ask you to come back later.',
        SAP_SIM_ORD_CLIENTERR:
            'Connection Issues (Unknown Parameter): Bad news: This service is currently unavailable. Good news: If you can read this, our support team is already working on it. Please come back later.',
        SAP_SIM_ORD_PROCERR:
            'Processing Issue (Unknown 4XX error): Oops, somebody must have deleted this part of the internet. But our support team is giving everything to bring it back. This can take a minute. Please come back later, refresh this site, and log back in.',
    }
    : {
        SAP_SIM_ORD_CONNECTION:
            'Session time out. Our support team has been notified. We apologize for the inconvenience and kindly ask you to come back later.',
        SAP_SIM_ORD_SERVER:
            'This service is currently unavailable. Our support team has been notified. We apologize for the inconvenience and kindly ask you to come back later.',

        SAP_SIM_ORD_DATAERR:
            'This service is currently unavailable. Our support team is already working on fixing the error. We apologize for the inconvenience and kindly ask you to come back later.',

        SAP_SIM_ORD_CLIENTERR:
            'This service is currently unavailable. Our support team is already working on fixing the error. We apologize for the inconvenience and kindly ask you to come back later.',

        SAP_SIM_ORD_PROCERR:
            'This service is currently unavailable. Our support team is already working on fixing the error. We apologize for the inconvenience and kindly ask you to come back later.',
    };
